import React from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import Sidebar from "../../../components/Sidebar";
import { Footer } from "../../../components/Footer";
import MobileWarningModal from "../../../components/MobileWarningModal/MobileWarningModal";
import { UpdateModal } from "../../../components/UpdatePopups";
import { Header } from "../../../components/Header";

function DefaultLayout({ children }) {
  return (
    <>
      <MobileWarningModal />
      <UpdateModal />
      <div className="d-flex">
        <div className="d-flex flex-column w-100">
          <Header />
          <div className="h-100vh mt-4">{children}</div>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default DefaultLayout;
