import React, { useState, useEffect, useMemo, useCallback } from "react";
import "../css/calendar.css";
import { Col, Container, Row, Button, Card } from "react-bootstrap";
import { months } from "../data/monthsData";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

// Função para gerar os estilos dos confetes
const generateConfettiStyles = (): React.CSSProperties => {
  const colors = ["#aa880c", "#8f1515", "#2E8B57", "#1E90FF", "#FF69B4"];
  const size = Math.random() * 10 + 5;
  const left = Math.random() * 100;
  const duration = Math.random() * 3 + 2;

  return {
    backgroundColor: colors[Math.floor(Math.random() * colors.length)],
    width: `${size}px`,
    height: `${size}px`,
    left: `${left}%`,
    animationDuration: `${duration}s`,
    position: "absolute",
    top: "-10%",
  };
};

export const Calendar: React.FC = () => {

  const [currentMonthIndex, setCurrentMonthIndex] = useState(new Date().getMonth());
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [showConfetti, setShowConfetti] = useState(false);

  const currentMonth = months[currentMonthIndex];
  const today = new Date().getDate();

  useEffect(() => {
    const isBirthday = currentMonth.birthdays[today] !== undefined;
    setShowConfetti(isBirthday);
  }, [today, currentMonth]);

  const firstDayOfWeek = useMemo(() => {
    return new Date(currentYear, currentMonthIndex, 1).getDay();
  }, [currentYear, currentMonthIndex]);

  const getMessage = useCallback(() => {
    const isBirthday = currentMonth.birthdays[today];
    const isSpecialDay = currentMonth.specials[today];
    const isReminder = currentMonth.reminders?.[today];
    const isStateHoliday = currentMonth.stateHolidays?.[today];

    if (isBirthday) return isBirthday.message;
    if (isSpecialDay) return isSpecialDay.label;
    if (isReminder) return isReminder.message || isReminder.label;
    if (isStateHoliday) return isStateHoliday.message || isStateHoliday.label;

    return currentMonth.days?.[today]?.label || "Tenha um bom dia, estou corrigindo as mensagens!";
  }, [today, currentMonth]);

  const goToNextMonth = useCallback(() => {
    setCurrentMonthIndex((prev) => (prev + 1) % 12);
    if (currentMonthIndex === 11) {
      setCurrentYear((prev) => prev + 1);
    }
  }, [currentMonthIndex]);

  const goToPreviousMonth = useCallback(() => {
    setCurrentMonthIndex((prev) => (prev - 1 + 12) % 12);
    if (currentMonthIndex === 0) {
      setCurrentYear((prev) => prev - 1);
    }
  }, [currentMonthIndex]);

  const confettiElements = useMemo(() => {
    return Array.from({ length: 100 }).map((_, i) => (
      <div key={i} className="confetti" style={generateConfettiStyles()}></div>
    ));
  }, []);

  if (!currentMonth.name) {
    return <h2>Calendário não disponível para o mês atual.</h2>;
  }

  return (
    <Container className="mt-4">
      <Row>
        <Col md={8}>
          <div className="calendar-container">
            <div className="calendar-header">
              <Button variant="secondary" onClick={goToPreviousMonth}>
                <IoIosArrowBack />
              </Button>
              <h1>
                {currentMonth.name} {currentYear}
              </h1>
              <Button variant="secondary" onClick={goToNextMonth}>
                <IoIosArrowForward />
              </Button>
            </div>

            {showConfetti && <div className="confetti-container">{confettiElements}</div>}

            <div className={currentMonth.birthdays[today] ? "birthday-message" : "calendar-message"}>
              <span>
                {currentMonth.birthdays[today] ? `🎉 ${currentMonth.birthdays[today].message} 🎉` : getMessage()}
              </span>
            </div>

            <div className="calendar-grid mb-2">
              {["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"].map((day) => (
                <div key={day} className="calendar-sem">{day}</div>
              ))}
            </div>

            <div className="calendar-grid">
              {Array.from({ length: firstDayOfWeek }).map((_, i) => (
                <div key={`empty-${i}`} className="calendar-day empty"></div>
              ))}

              {Array.from({ length: currentMonth.daysInMonth(currentYear) }, (_, i) => i + 1).map((day) => {
                const isToday = day === today;
                const isBirthday = currentMonth.birthdays[day];
                const isSpecial = currentMonth.specials[day];
                const isReminder = currentMonth.reminders?.[day];
                const isStateHoliday = currentMonth.stateHolidays?.[day];

                return (
                  <div
                    key={day}
                    className={`calendar-day ${isToday ? "highlight" : ""}`}
                    style={{
                      borderColor:
                        isBirthday?.label ||
                        isSpecial?.label ||
                        isReminder?.label ||
                        isStateHoliday?.label ||
                        "var(--slate-300)",
                      background: isToday && isBirthday
                        ? "linear-gradient(45deg, var(--primary), #d12e2e, var(--primary))"
                        : "",
                    }}
                  >
                    {day}
                    {isBirthday && <span className="mark-day-birthday">🎉</span>}
                    {isSpecial && <span className="mark-day-special">⭐</span>}
                    {isReminder && <span className="mark-day-reminder">📌</span>}
                    {isStateHoliday && <span className="mark-day-state">📍</span>}
                  </div>
                );
              })}
            </div>
          </div>
        </Col>

        <Col md={4}>
          <Card style={{ borderTop: "var(--slate-300) 4px solid", borderBottom: "var(--slate-300) 4px solid" }}>
            <Card.Body>
              <div className="calendar-legend">
                <h2>Aniversários 🎉</h2>
                {Object.keys(currentMonth.birthdays).length > 0 ? (
                  Object.entries(currentMonth.birthdays).map(([day, { label }]) => (
                    <p key={day} className="birthday-entry">
                      <strong>{day}</strong>: {label}
                    </p>
                  ))
                ) : (
                  <p>Nenhum aniversário este mês.</p>
                )}
                <h2>Dias Especiais ⭐</h2>
                {Object.keys(currentMonth.specials).length > 0 ? (
                  Object.entries(currentMonth.specials).map(([day, { label }]) => (
                    <p key={day} className="special-entry">
                      <strong>{day}</strong>: {label}
                    </p>
                  ))
                ) : (
                  <p>Nenhum dia especial este mês.</p>
                )}
                <h2>Lembretes 📌</h2>
                {Object.keys(currentMonth.reminders || {}).length > 0 ? (
                  Object.entries(currentMonth.reminders!).map(([day, { label }]) => (
                    <p key={day} className="reminder-entry">
                      <strong>{day}</strong>: {label}
                    </p>
                  ))
                ) : (
                  <p>Nenhum lembrete este mês.</p>
                )}

                <h2>Feriados RN 📍</h2>
                {Object.keys(currentMonth.stateHolidays || {}).length > 0 ? (
                  Object.entries(currentMonth.stateHolidays!).map(([day, { label }]) => (
                    <p key={day} className="state-entry">
                      <strong>{day}</strong>: {label}
                    </p>
                  ))
                ) : (
                  <p>Nenhum feriado estadual este mês.</p>
                )}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
