import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Col,
  Row,
  Image,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import { FaGift, FaHandshake } from "react-icons/fa"; // Exemplos de ícones do react-icons
import { MdAutoAwesome, MdFace, MdFace2 } from "react-icons/md";
import "./style.css";
import {
  FaUserCircle,
  FaDollarSign,
  FaLaptopCode,
  FaUserGraduate,
  FaBook,
} from "react-icons/fa";
import { MdHeadsetMic } from "react-icons/md";
import {
  GiBirdTwitter,
  GiBrain,
  GiCat,
  GiFox,
  GiHeartWings,
  GiPumpkinMask,
  GiRose,
  GiLabradorHead,
  GiCoffeeCup,
} from "react-icons/gi";
import { RiReactjsLine } from "react-icons/ri";
import { PiGithubLogoFill } from "react-icons/pi";
import Icon from "../icons";

export const IconProfile: React.FC = () => {
  const [selectedIcon, setSelectedIcon] = useState<string>("FaUserCircle"); // Ícone padrão
  const [iconColor, setIconColor] = useState<string>("var(--secondary)"); // Cor padrão
  const [showModal, setShowModal] = useState(false); // Estado do Modal

  // Lista de ícones para seleção
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const iconOptions = {
    FaUserCircle: <FaUserCircle size={40} color={iconColor} />,
    MdFace: <MdFace size={40} color={iconColor} />,
    MdFace2: <MdFace2 size={40} color={iconColor} />,
    RiUser3Fill: <MdAutoAwesome size={40} color={iconColor} />,
    FaDollarSign: <FaDollarSign size={40} color={iconColor} />,
    FaHandshake: <FaHandshake size={40} color={iconColor} />,
    PiGithubLogoFill: <PiGithubLogoFill size={40} color={iconColor} />,
    GiHeartWings: <GiHeartWings size={40} color={iconColor} />,
    GiCoffeeCup: <GiCoffeeCup size={40} color={iconColor} />,
    FaLaptopCode: <FaLaptopCode size={40} color={iconColor} />,
    FaUserGraduate: <FaUserGraduate size={40} color={iconColor} />,
    FaBook: <FaBook size={40} color={iconColor} />,
    GiBrain: <GiBrain size={40} color={iconColor} />,
    MdHeadsetMic: <MdHeadsetMic size={40} color={iconColor} />,
    RiReactjsLine: <RiReactjsLine size={40} color={iconColor} />,
    GiLabradorHead: <GiLabradorHead size={40} color={iconColor} />,
    GiCat: <GiCat size={40} color={iconColor} />,
    GiBirdTwitter: <GiBirdTwitter size={40} color={iconColor} />,
    GiFox: <GiFox size={40} color={iconColor} />,
    GiPumpkinMask: <GiPumpkinMask size={40} color={iconColor} />,
    FaGift: <FaGift size={40} color={iconColor} />,
    GiRose: <GiRose size={40} color={iconColor} />,

    A: <div style={{ fontSize: "40px", color: iconColor }}>A</div>,
    B: <div style={{ fontSize: "40px", color: iconColor }}>B</div>,
    C: <div style={{ fontSize: "40px", color: iconColor }}>C</div>,
    D: <div style={{ fontSize: "40px", color: iconColor }}>D</div>,
    E: <div style={{ fontSize: "40px", color: iconColor }}>E</div>,
    F: <div style={{ fontSize: "40px", color: iconColor }}>F</div>,
    G: <div style={{ fontSize: "40px", color: iconColor }}>G</div>,
    H: <div style={{ fontSize: "40px", color: iconColor }}>H</div>,
    I: <div style={{ fontSize: "40px", color: iconColor }}>I</div>,
    J: <div style={{ fontSize: "40px", color: iconColor }}>J</div>,
    K: <div style={{ fontSize: "40px", color: iconColor }}>K</div>,
    L: <div style={{ fontSize: "40px", color: iconColor }}>L</div>,
    M: <div style={{ fontSize: "40px", color: iconColor }}>M</div>,
    N: <div style={{ fontSize: "40px", color: iconColor }}>N</div>,
    O: <div style={{ fontSize: "40px", color: iconColor }}>O</div>,
    P: <div style={{ fontSize: "40px", color: iconColor }}>P</div>,
    Q: <div style={{ fontSize: "40px", color: iconColor }}>Q</div>,
    R: <div style={{ fontSize: "40px", color: iconColor }}>R</div>,
    S: <div style={{ fontSize: "40px", color: iconColor }}>S</div>,
    T: <div style={{ fontSize: "40px", color: iconColor }}>T</div>,
    U: <div style={{ fontSize: "40px", color: iconColor }}>U</div>,
    V: <div style={{ fontSize: "40px", color: iconColor }}>V</div>,
    W: <div style={{ fontSize: "40px", color: iconColor }}>W</div>,
    X: <div style={{ fontSize: "40px", color: iconColor }}>X</div>,
    Y: <div style={{ fontSize: "40px", color: iconColor }}>Y</div>,
    Z: <div style={{ fontSize: "40px", color: iconColor }}>Z</div>,
  };

  const colorOptions = [
    "#dc3545",
    "#EAB308",
    "#EF5900",
    "var(--secondary)",
    "#6610f2",
    "#e83e8c",
    "#F472B6",
    "#DB2777",
    "#10B981",
    "#34C759",
    "#28a745",
  ];

  // Efeito para carregar o ícone e a cor salvos no localStorage
  useEffect(() => {
    const savedIcon = localStorage.getItem("profileIcon");
    const savedColor = localStorage.getItem("profileIconColor");

    if (savedIcon && iconOptions[savedIcon]) {
      setSelectedIcon(savedIcon);
    }
    if (savedColor) {
      setIconColor(savedColor);
    }
  }, [iconOptions]);

  // Função para atualizar o ícone selecionado e salvar no localStorage
  const handleIconChange = (icon: string) => {
    setSelectedIcon(icon);
    localStorage.setItem("profileIcon", icon); // Salva no localStorage
  };

  // Função para atualizar a cor do ícone
  const handleColorChange = (color: string) => {
    setIconColor(color);
    localStorage.setItem("profileIconColor", color); // Salva no localStorage
  };

  return (
    <div className="icon-profile-container" style={{ position: "relative" }}>
      {/* Ícone de perfil selecionado */}
      <div onClick={() => setShowModal(true)} className="profile-icon">
        {iconOptions[selectedIcon]}
      </div>

      {/* Ícone de edição que abre o modal */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          position: "absolute",
          bottom: "-20px",
          cursor: "pointer",
          left: "0",
          background: "var(--bg-secondary)",
          borderRadius: "var(--br-sm)",
          width: "100%",
        }}
        onClick={() => setShowModal(true)}
      >
        <Icon className="edit-dropdown" size={32} icon="FaEdit" />
        <OverlayTrigger
          placement="left"
          overlay={<Tooltip id="edit-tooltip">Editar ícone de perfil</Tooltip>}
        >
          <Icon className="edit-dropdown" size={32} icon="FaEdit" />
        </OverlayTrigger>
      </div>

      {/* Modal para selecionar ícone e cor */}
      <Modal size="lg" show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Escolha seu ícone de perfil</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            {/* Coluna de Ícones */}
            <Col xs={12}>
              <div className="d-flex align-items-center justify-content-center flex-wrap">
                {Object.keys(iconOptions).map((icon) => (
                  <div
                    key={icon}
                    onClick={() => handleIconChange(icon)}
                    className="icon-img"
                    style={{
                      cursor: "pointer",
                      margin: "10px",
                      boxShadow:
                        selectedIcon === icon
                          ? "0 0 0 2px var(--primary)"
                          : "none",
                      background: "var(--slate-50)",
                      width: "50px",
                      height: "50px",
                      borderRadius: "var(--br-md)",
                      padding: "5px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontWeight: "bold",
                      fontFamily: "monospace",
                    }}
                  >
                    {iconOptions[icon]}
                  </div>
                ))}
              </div>
            </Col>

            {/* Coluna de Cores */}
            <Col xs={12}>
              <div className="d-flex flex-wrap justify-content-between">
                {colorOptions.map((color) => (
                  <div
                    key={color}
                    onClick={() => handleColorChange(color)}
                    style={{
                      width: "40px",
                      height: "40px",
                      borderRadius: "50%",
                      backgroundColor: color,
                      cursor: "pointer",
                      margin: "10px",
                      border:
                        iconColor === color
                          ? "2px solid var(--bg-default)"
                          : "none",
                    }}
                  />
                ))}
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
