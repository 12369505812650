import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import {
  RiFileListFill,
  RiMailUnreadFill,
  RiLogoutBoxRFill,
  RiMoonClearFill,
  RiSunFill,
  RiFileCheckFill,
} from "react-icons/ri";

import { signOut } from "src/store/modules/auth/actions";
import Avatar from "../../../assets/Images/Avatar.png";
import { Footer } from "../../../components/Footer";
import MobileWarningModal from "../../../components/MobileWarningModal/MobileWarningModal";
import QuickActions from "../../../components/QuickActions";
import { Header } from "./Header";

function CompanyLayout({ children }) {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.user.profile);
  const location = useLocation();

  const handleSignOut = () => {
    dispatch(signOut(true));
  };

  return (
    <div className="d-flex flex-column w-100 ">
      <MobileWarningModal />
      <Header />
      <div className="h-100vh mt-4">{children}</div>
      <Footer />
    </div>
  );
}

export default CompanyLayout;
