import React from "react";
import "./style.css";

export const Footer = () => {
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  return (
    <footer>
      <p>
        Estagius todos os direitos reservados 2019 - {getCurrentYear()} |
        <b className="text-primary ml-2">v1.5.3</b>
      </p>
    </footer>
  );
};
