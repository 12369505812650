import React from "react";
import { useSelector } from "react-redux";
import { FaBars } from "react-icons/fa";
import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";
import "./style.css";
import { Link, useLocation } from "react-router-dom";

const NAV_LINKS = [
  { path: "/dashboard", label: "Painel de Controle" },
  { path: "/agenda", label: "Agenda" },
  { path: "/cursos", label: "Cursos" },
];

const DROPDOWN_LINKS = [
  {
    title: "Cadastros",
    id: "cadastros-dropdown",
    links: [
      { path: "/empresas", label: "Empresas" },
      { path: "/estagiarios", label: "Estagiários" },
      { path: "/instituicao_educacao", label: "Instituição de educação" },
      { path: "/grupos", label: "Grupos" },
      { path: "/contatos", label: "Contatos" },
    ],
  },
  {
    title: "Contratos",
    id: "contratos-dropdown",
    links: [
      { path: "/contratos", label: "Contratos" },
      { path: "/relatorios/contratos_ativos", label: "Contratos Ativos" },
      {
        path: "/relatorios/contratos_desligados",
        label: "Contratos Desligados",
      },
      { path: "/relatorios/contratos_expirados", label: "Contratos Expirados" },
      { path: "/contratos/solicitacoes", label: "Solicitações" },
    ],
  },
  {
    title: "Pedagógico",
    id: "pedagogico-dropdown",
    links: [
      { path: "/relatorios/presencas", label: "Relatório de presenças" },
      {
        path: "/relatorios/presencas/contagem",
        label: "Contagens de presenças",
      },
    ],
  },
];

const PROFILE_LINKS = [
  {
    path: "/relatorios/financeiro",
    label: "Financeiro",
    condition: "financial_access",
  },
];

export const Navigation = () => {
  const profile = useSelector((state: any) => state.user.profile);
  const location = useLocation();
  return (
    <Navbar expand="lg" className="navigation">
      <Container>
        <Navbar.Toggle aria-controls="basic-navbar-nav">
          <FaBars />
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav" className="navigation__collapse">
          <Nav className="mr-auto navigation__menu">
            {NAV_LINKS.map(({ path, label }) => (
              <Nav.Link
                as={Link}
                to={path}
                key={path}
                className={`navigation__link ${
                  location.pathname === path ? "active__link" : ""
                }`}
              >
                {label}
              </Nav.Link>
            ))}
            {PROFILE_LINKS.map(({ path, label, condition }) =>
              profile[condition] ? (
                <Nav.Link
                  as={Link}
                  to={path}
                  key={path}
                  className={`navigation__link ${
                    location.pathname === path ? "active__link" : ""
                  }`}
                >
                  {label}
                </Nav.Link>
              ) : null
            )}
            {DROPDOWN_LINKS.map(({ title, id, links }) => (
              <NavDropdown
                className="navigation__link "
                title={title}
                id={id}
                key={id}
              >
                {links.map(({ path, label }) => (
                  <NavDropdown.Item as={Link} to={path} key={path}>
                    {label}
                  </NavDropdown.Item>
                ))}
              </NavDropdown>
            ))}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
