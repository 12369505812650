import React from "react";
import { Container, Card, Button, Row, Col, Accordion } from "react-bootstrap";
import { FaChevronDown } from "react-icons/fa";
import Cartao from "../../../assets/solicitacoes/cartao-pre-cadastro.jpg";
import ProcessoSeletivo from "../../../assets/solicitacoes/Slide-para-o-processo-seletivo.pptx";
import Cartilha from "../../../assets/solicitacoes/Cartilha-financeira.pdf";
import Restricoes from "../../../assets/solicitacoes/RESTRIÇÃO-ATIVIDADES.pdf";
import Permicoes from "../../../assets/solicitacoes/Atividades-permitidas.pdf";
import Diretrizes from "../../../assets/solicitacoes/Diretrizes-contratações.pdf";

import "./style.css";

const Actions = [
  {
    label: "Contrato de estágio",
    description: (
      <>
        <p>
          Utilize este campo para que sua empresa solicite contratos de estágio,
          assegurando a formalização e regularização dos estagiários conforme as
          normas vigentes.
        </p>
        <p>
          Verifique todas as informações antes de enviar a solicitação. Nossa
          equipe entrará em contato para concluir o processo e fornecer o
          suporte necessário.
        </p>
        <p>
          <strong>
            Fique atento às regras de contratação. Baixe o PDF com as
            orientações completas.
          </strong>
        </p>
      </>
    ),
    link: "/solicitacoes/novo",
  },
  {
    label: "Desligamento de estágio",
    description: (
      <>
        <p>
          Utilize este campo para solicitar o desligamento formal de estagiários
          da sua empresa. Por favor, forneça as informações necessárias, como o
          nome do estagiário, data prevista para o desligamento, e o motivo do
          encerramento do estágio.
        </p>
        <p>
          Certifique-se de revisar todos os dados antes de enviar a solicitação.
          Nossa equipe processará o pedido e entrará em contato para confirmar
          os próximos passos.
        </p>
      </>
    ),
    variant: "danger",
    link: "https://form.jotform.com/241766196179672",
  },
  {
    label: "Inclusão de CNPJ",
    description: (
      <>
        <p>
          Insira o CNPJ da sua empresa neste campo. O CNPJ é um identificador
          essencial para formalizar contratos, solicitações e outras operações
          relacionadas à sua empresa.
        </p>
        <p>
          Verifique se o número inserido está correto e atualizado antes de
          enviar.
        </p>
      </>
    ),
    link: "https://form.jotform.com/233025932642654",
  },
];

const Material = [
  {
    title: "Cartão Pré-cadastro",
    description:
      "Deve ser entregue ao candidato que for selecionado para a vaga de estágio. Com este folder, ele vai entrar em contato com a nossa equipe.",
    file: Cartao,
    buttonText: "Ver Cartão",
  },
  {
    title: "Slide para o processo seletivo",
    description: "Pode ser usado no momento da sua seleção de candidatos.",
    file: ProcessoSeletivo,
    buttonText: "Ver Slide",
  },
  {
    title: "Cartilha Financeira",
    description: "Tire suas principais dúvidas sobre questões financeiras.",
    file: Cartilha,
    buttonText: "Ver Cartilha",
  },
];

const Orientation = [
  {
    title: "Diretrizes para contratação",
    description:
      "Conheça as regras e diretrizes para contratação de estagiários. Este documento contém informações importantes sobre a formalização de contratos e a relação entre empresa e estagiário.",
    file: Diretrizes,
    buttonText: "Ver Diretrizes",
  },
  {
    title: "Atividades permitidas",
    description: "Saiba quais atividades são permitidas para estagiários.",
    file: Permicoes,
    buttonText: "Ver Permissões",
  },
  {
    title: "Restrições para atividades",
    description: "Conheça as restrições para atividades dos estagiários.",
    file: Restricoes,
    buttonText: "Ver Restrições",
  },
];

const RenderActionsCard = ({
  label,
  description,
  link,
}: {
  label: string;
  description: React.ReactNode;
  link: string;
}) => (
  <Card>
    <Accordion.Toggle
      as="div"
      eventKey={label}
      className="d-flex bg-secondary-c justify-content-between align-items-center mb-2"
      style={{
        padding: "15px",
        cursor: "pointer",
        border: "solid 1px var(--slate-300)",
        borderRadius: "var(--br-md)",
        minHeight: "72px",
      }}
    >
      <div className="d-flex align-items-center">
        <p>{label}</p>
      </div>
      <div className="d-flex gap-4">
        {link && (
          <Button variant="primary" href={link} target="_blank">
            Acessar
          </Button>
        )}
        <FaChevronDown />
      </div>
    </Accordion.Toggle>
    <Accordion.Collapse eventKey={label}>
      <Card.Body>{description}</Card.Body>
    </Accordion.Collapse>
  </Card>
);

const RenderMaterialCard = ({ title, description, file, buttonText }) => (
  <Card style={{ boxShadow: "none" }}>
    <Accordion.Toggle
      as="div"
      eventKey={title}
      className="d-flex bg-secondary-c justify-content-between align-items-center mb-2"
      style={{
        padding: "15px",
        cursor: "pointer",
        border: "solid 1px var(--slate-300)",
        borderRadius: "var(--br-md)",
        minHeight: "72px",
      }}
    >
      <p>{title}</p>
      <div className="d-flex gap-4">
        <Button
          style={{ width: "120px" }}
          variant="primary"
          href={file}
          target="_blank"
        >
          {buttonText}
        </Button>
        <FaChevronDown />
      </div>
    </Accordion.Toggle>
    <Accordion.Collapse eventKey={title}>
      <Card.Body>
        <p>{description}</p>
      </Card.Body>
    </Accordion.Collapse>
  </Card>
);

const RenderOrientationCard = ({ title, description, file, buttonText }) => (
  <Card style={{ boxShadow: "none" }}>
    <Accordion.Toggle
      as="div"
      eventKey={title}
      className="d-flex bg-secondary-c justify-content-between align-items-center mb-2"
      style={{
        padding: "15px",
        cursor: "pointer",
        border: "solid 1px var(--slate-300)",
        borderRadius: "var(--br-md)",
        minHeight: "72px",
      }}
    >
      <p>{title}</p>
      <div className="d-flex gap-4">
        <Button
          style={{ width: "140px" }}
          variant="primary"
          href={file}
          target="_blank"
        >
          {buttonText}
        </Button>
        <FaChevronDown />
      </div>
    </Accordion.Toggle>
    <Accordion.Collapse eventKey={title}>
      <Card.Body>
        <p>{description}</p>
      </Card.Body>
    </Accordion.Collapse>
  </Card>
);

export const Solicitations: React.FC = () => {
  return (
    <Container fluid>
      <Card className="solicitations-home">
        <div className="text-center mb-4">
          <h1>Bem-vindo ao Estagius</h1>
          <p>
            Gerencie todas as solicitações e informações de estagiários
            diretamente pelo sistema.
          </p>
        </div>
        <Row style={{ rowGap: "1rem" }}>
          <Col md={6}>
            <h2 className="text-center">Solicitações</h2>
            <hr />
            <Accordion>
              {Actions.map((info, index) => (
                <RenderActionsCard key={index} {...info} />
              ))}
            </Accordion>
          </Col>

          <Col md={6}>
            <h2 className="text-center">Material de Apoio</h2>
            <hr />
            <Accordion>
              {Material.map((info, index) => (
                <RenderMaterialCard key={index} {...info} />
              ))}
            </Accordion>
          </Col>

          <Col md={6}>
            <h2 className="text-center">Orientações</h2>
            <hr />
            <Accordion>
              {Orientation.map((info, index) => (
                <RenderOrientationCard key={index} {...info} />
              ))}
            </Accordion>
          </Col>
        </Row>
      </Card>
    </Container>
  );
};
