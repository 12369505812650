import React, { useState, useEffect } from 'react';
import { Button, Modal, Table } from 'react-bootstrap';
import api from 'src/services/axios';

interface EstadoQuantidade {
    estado: string;
    quantidade: number;
}

interface modalDetailsProps {
    url: string;
}

export const ModalDetails: React.FC<modalDetailsProps> = ({ url }) => {
    const [showModal, setShowModal] = useState(false);
    const [data, setData] = useState<EstadoQuantidade[]>([]);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        if (url) {
            // Substituindo a chamada à API por um array fixo para teste
            setData([
                { estado: 'São Paulo', quantidade: 10 },
                { estado: 'Rio de Janeiro', quantidade: 5 },
                { estado: 'Minas Gerais', quantidade: 8 },
            ]);
            setError(null);
        }
    }, [url]);

    const handleOpenModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    return (
        <>
            <Button className="d-none mt-2" variant="primary" onClick={handleOpenModal}>
                ver detalhes
            </Button>

            <Modal show={showModal} onHide={handleCloseModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Detalhes dos Contratos</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {error ? (
                        <p>{error}</p>
                    ) : (
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Estado</th>
                                    <th>Quantidade</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.estado}</td>
                                        <td>{item.quantidade}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Fechar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

