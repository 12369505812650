import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  FaSun,
  FaMoon,
  FaRegEyeSlash,
  FaEye,
  FaSignOutAlt,
  FaBars,
} from "react-icons/fa";
import {
  Tooltip,
  OverlayTrigger,
  Navbar,
  Nav,
  Image,
  Container,
  NavDropdown,
} from "react-bootstrap";
import { IconProfile } from "../../components/IconProfile";
import { signOut } from "src/store/modules/auth/actions";
import "./style.css";
import Logo from "../../assets/Images/Logo.svg";
import { Link, useLocation } from "react-router-dom";
import { Navigation } from "../Navigation";

export const Header: React.FC = () => {
  const dispatch = useDispatch();
  const profile = useSelector((state: any) => state.user.profile);
  const [theme, setTheme] = useState("light");
  const [isHighContrast, setIsHighContrast] = useState(false);

  const toggleTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    document.body.classList.remove(theme);
    document.body.classList.add(newTheme);
    setTheme(newTheme);
    localStorage.setItem("selected-theme", newTheme);
  };

  const toggleHighContrast = () => {
    if (isHighContrast) {
      document.body.classList.remove("high-contrast");
    } else {
      document.body.classList.add("high-contrast");
    }
    setIsHighContrast(!isHighContrast);
    localStorage.setItem("high-contrast", (!isHighContrast).toString());
  };

  useEffect(() => {
    const savedTheme = localStorage.getItem("selected-theme") || "light";
    const savedHighContrast = localStorage.getItem("high-contrast") === "true";
    document.body.classList.add(savedTheme);
    if (savedHighContrast) {
      document.body.classList.add("high-contrast");
    }
    setTheme(savedTheme);
    setIsHighContrast(savedHighContrast);
  }, []);

  return (
    <>
      <header className="header" id="header">
        <div className="header__container">
          <Image className="header__logo" src={Logo} />
          <div className="navigation__hidden">
            <Navigation />
          </div>
          <div className="header__right">
            <div className="header__actions_controls">
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="tooltip-logout">Desconectar</Tooltip>}
              >
                <button
                  onClick={() => dispatch(signOut())}
                  className="actions__button"
                >
                  <FaSignOutAlt />
                </button>
              </OverlayTrigger>

              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip id="tooltip-light-dark-mode">
                    {theme === "light" ? "Modo escuro" : "Modo claro"}
                  </Tooltip>
                }
              >
                <button className="actions__button" onClick={toggleTheme}>
                  {theme === "light" ? <FaMoon /> : <FaSun />}
                </button>
              </OverlayTrigger>

              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip id="tooltip-high-contrast">
                    {isHighContrast
                      ? "Desativar Alto Contraste"
                      : "Ativar Alto Contraste"}
                  </Tooltip>
                }
              >
                <button
                  className="actions__button"
                  onClick={toggleHighContrast}
                >
                  {isHighContrast ? <FaEye /> : <FaRegEyeSlash />}
                </button>
              </OverlayTrigger>
            </div>

            <div className="d-flex align-items-center flex-row">
              <span className="header__profile-name">{profile?.name}</span>
              <div className="header__profile">
                <IconProfile />
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className="navigation__responsive">
        <Navigation />
      </div>
    </>
  );
};
