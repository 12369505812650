import { SelectOption } from "src/types/GlobalTypes";
import api from "./axios";

class TraineeService {
  private endpoint = "/trainees";

  async fetchAllContracts(traineeId: number) {
    const { data } = await api.get(`${this.endpoint}/${traineeId}/contracts`);
    return data as SelectOption[];
  }
}

const traineeService = new TraineeService();

export default traineeService;