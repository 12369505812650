import React from "react";
import { useSelector } from "react-redux";
import { FaBars } from "react-icons/fa";
import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";
import "./style.css";
import { Link, useLocation } from "react-router-dom";

const NAV_LINKS = [
  { path: "/solicitacoes", label: "Solicitações" },
  { path: "/solicitacoes/feitas", label: "Minha solicitações" },
  { path: "/relatorios/empresas/presencas", label: "Presenças" },
  { path: "/relatorios/empresas/contagem", label: "Contagem de Presenças" },
];

export const Navigation = () => {
  const location = useLocation();
  return (
    <Navbar expand="lg" className="navigation">
      <Container>
        <Navbar.Toggle aria-controls="basic-navbar-nav">
          <FaBars />
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav" className="navigation__collapse">
          <Nav className="mr-auto navigation__menu">
            {NAV_LINKS.map(({ path, label }) => (
              <Nav.Link
                as={Link}
                to={path}
                key={path}
                className={`navigation__link ${
                  location.pathname === path ? "active__link" : ""
                }`}
              >
                {label}
              </Nav.Link>
            ))}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
