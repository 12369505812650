import React, { useState,  useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container'

import ComplexTable from '../../../../components/ComplexTable';
import api from 'src/services/axios';

const SolicitationsList: React.FC = () => {
    const history = useHistory();

    const [contracts, setContracts] = useState([]);

    const getSolicitations = useCallback(() => {
        api
        .get(`/solicitations`).then((response) => {
            setContracts(response.data);
        })
        .catch(() => toast.error('Erro ao buscar as solicitações'))
    }, []);

    useEffect(() => {
        getSolicitations()
    }, [])

    return (
        <Container fluid>
            <Card style={{ boxShadow: 'var(--card-bs)' }}>
                <Card.Header as="h5" className='d-flex justify-content-between align-items-center color-secondary'>
                        Solicitações realizadas
                
                        <Button
                            onClick={() => history.push('/solicitacoes/novo')}
                            variant='success'
                        >
                            <i className="bi bi-plus-lg mr-2"></i>
                            Novo Contrato
                        </Button>
             
                </Card.Header>
                <Card.Body>
                    <ComplexTable
                        data={contracts}
                        columns={[

                            {
                                dataField: 'trainee_name',
                                text: 'Estagiário',
                                sort: true,
                                filter: false,
                            },


                            {
                                dataField: 'trainee_document',
                                text: 'Documento',
                                sort: true,
                                filter: false,
                            }
                        ]}
                    />
                </Card.Body>
            </Card>
        </Container>
    );
};

export default SolicitationsList;
