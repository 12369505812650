import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";

// Array com as atualizações
const updates = [
  {
    id: "update-1",
    title: "Melhorias no menu de navegação",
    message:
      "De acordo com as prefrencias da maioria dos usuarios, implementamos um menu no topo fixo, e agora estagius tem finalmente seu visual final, todas as correções e melhorias visuais foram implementadas nesta nova versão. Exprimente as ações integrada no menu: desconectar, troca de tema e alto contraste.",
  },
];

export const UpdateModal: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(true);

  // Verifica se há atualizações não vistas
  useEffect(() => {
    const seenUpdates = JSON.parse(
      localStorage.getItem("seenUpdates4") || "[]"
    );
    const newUpdates = updates.filter(
      (update) => !seenUpdates.includes(update.id)
    );

    if (newUpdates.length === 0) {
      setIsModalVisible(false);
    }
  }, []);

  // Fecha o modal e salva as atualizações vistas
  const closeModal = () => {
    const seenUpdates = updates.map((update) => update.id);
    localStorage.setItem("seenUpdates4", JSON.stringify(seenUpdates));
    setIsModalVisible(false);
  };

  if (!isModalVisible) return null;

  return (
    <Modal centered size="lg" show={isModalVisible} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>Atualização - Update 1.5.3</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {updates.map((update) => (
          <div key={update.id} className="mb-3">
            <h4>{update.title}</h4>
            <p>{update.message}</p>
          </div>
        ))}
      </Modal.Body>

      <Modal.Footer className="justify-content-between">
        <Button
          target="blank"
          className="d-none"
          href="https://docs.google.com/forms/d/e/1FAIpQLScSJkkn8ZoShWemUPjmHzF1d-7BCeVIPLjKhRo-z620wUuHFg/viewform?usp=dialog"
          variant="primary"
        >
          Acessar formulário
        </Button>
        <Button variant="danger" onClick={closeModal}>
          Fechar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
