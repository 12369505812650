import React, { useState, useEffect } from "react";
import { ProgressBar, Container, ButtonGroup, Button } from "react-bootstrap";
import Firework from "../../../assets/Images/firework.gif";
import "../css/progressbarMeta.css";

 type ProgressBarMetaProps = {
  value: number; // Quantidade de estagiários cadastrados no sistema
};

const yearGoals = {
  2024: { steps: [700, 750, 800, 850, 900, 950, 1000], finalGoal: 1000, completed: true },
  2025: { steps: [1100, 1200, 1300, 1400, 1500, 1600, 1700], finalGoal: 1700, completed: false },
};

const ProgressBarMeta: React.FC<ProgressBarMetaProps> = ({ value = 0 }) => {
  const [selectedYear, setSelectedYear] =
    useState<keyof typeof yearGoals>(2025);
  const [showConfetti, setShowConfetti] = useState(false);
  const { steps, finalGoal, completed } = yearGoals[selectedYear];

  const adjustedValue = completed ? finalGoal : value;

  const calculatePercentage = (value: number): number => {
    const percentage = (value / finalGoal) * 100;
    return percentage > 100 ? 100 : percentage;
  };

  useEffect(() => {
    if (steps.some((step) => value >= step)) {
      setShowConfetti(true);
      setTimeout(() => setShowConfetti(false), 5000);
    }
  }, [value, steps]);

  return (
    <Container>
      <div className="progress-meta-container">
        <div className="d-flex mb-3 justify-content-between align-items-center">
          <h5 className="progress-title">
            Progresso da Meta de {selectedYear}
          </h5>

          <ButtonGroup>
            {Object.keys(yearGoals).map((year) => (
              <Button
                key={year}
                variant={
                  parseInt(year) === selectedYear ? "primary" : "secondary"
                }
                onClick={() =>
                  setSelectedYear(parseInt(year) as keyof typeof yearGoals)
                }
              >
                {year}
              </Button>
            ))}
          </ButtonGroup>
        </div>

        <div className="progress-wrapper">
          <ProgressBar
            now={calculatePercentage(adjustedValue)}
            animated={!completed}
            className="progress-bar-meta"
          />
          <span className="progress-percentage">
            {calculatePercentage(adjustedValue).toFixed(0)}%
          </span>

          {steps.map((step, index) => (
            <div
              key={index}
              className={`progress-step-marker ${
                adjustedValue >= step ? "reached" : ""
              }`}
              style={{ left: `${(step / finalGoal) * 100}%` }}
            >
              <div className="step-marker-dot">
                <span className="step-number">{step}</span>
              </div>
              {adjustedValue >= step && showConfetti && (
                <img src={Firework} alt="Confetti Animation" className="confetti-gif" />
              )}
            </div>
          ))}
        </div>
        {completed && (
          <div className="goal-completed-message">Meta concluída!</div>
        )}
      </div>
    </Container>
  );
};

export default ProgressBarMeta;
