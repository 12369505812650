import React, { useState, useEffect, useCallback } from "react";
import { useForm, Controller } from "react-hook-form";
import { differenceInDays, format, isSameMonth, parseISO } from "date-fns";

import { handleGenerateReportFinancial } from "../templates/report";

// Componentes do react-bootrap
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";

import ExportJsonExcel from "js-export-excel";

// Componentes da aplicação
import Select from "../../../components/Select";
import InputDate from "../../../components/InputDate";
import ComplexTable from "../../../components/ComplexTable";

import api from "src/services/axios";
import { Col, Row } from "react-bootstrap";
import { ptBR } from "date-fns/locale";
import { FaFilePdf, FaFilter } from "react-icons/fa";
import { RiFileExcel2Fill } from "react-icons/ri";
import { LoadingIndicator } from "../../../components/LoadingIndicator";

const FinancialReport: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [contracts, setContracts] = useState<any>([]);

  const [companiesOptions, setCompaniesOptions] = useState([
    {
      value: "",
      label: "Todas",
    },
  ]);

  const { handleSubmit, control, watch, getValues } = useForm({
    reValidateMode: "onSubmit",
    defaultValues: {
      company_id: [
        {
          value: "",
          label: "Todas",
        },
      ],
      ignore_contracts_date: undefined,

      date_start_end_validity: undefined,
      date_end_end_validity: undefined,
      omitir_value: {
        value: false,
        label: "Não",
      },
      showData: {
        value: "",
        label: "Todos os registros",
      },
    },
  });

  const omitir_value = watch("omitir_value");
  const showData = watch("showData");

  const calcValueProportional = (tax: number, days: number) =>
    (Number(tax) / 30) * days;

  const applyRuleIsHigherEducation = (
    isHigherEducation: boolean,
    value: number
  ) => (isHigherEducation ? 0 : value);

  const handleSetReport = useCallback(
    (data) => {
      const valueForm = getValues();

      const dataActives = data.actives.map((contract) => {
        const parseContract: any = {};

        const startValidity = parseISO(
          contract.start_validity.substring(0, 10)
        );
        const dayStartValidity = Number(
          format(startValidity, "dd", { locale: ptBR })
        );
        const daysToCalcValueProportional = 31 - dayStartValidity;
        //console.log(daysToCalcValueProportional)

        if (contract.company.type_charge === "INTEGRAL") {
          if (isSameMonth(startValidity, valueForm.ignore_contracts_date)) {
            if (dayStartValidity < 24) {
              parseContract.integration_agent_value_company_total =
                contract.company.integration_agent_value;
              parseContract.institution_value_company_total =
                applyRuleIsHigherEducation(
                  contract.is_higher_education,
                  contract.company.institution_value
                );
            } else {
              parseContract.integration_agent_value_company_total = 0;
              parseContract.institution_value_company_total = 0;
            }
          } else {
            parseContract.integration_agent_value_company_total =
              contract.company.integration_agent_value;
            parseContract.institution_value_company_total =
              applyRuleIsHigherEducation(
                contract.is_higher_education,
                contract.company.institution_value
              );
          }
        } else if (contract.company.type_charge === "PROPORTIONAL") {
          if (isSameMonth(startValidity, valueForm.ignore_contracts_date)) {
            if (dayStartValidity < 24) {
              console.log(contract.company);
              parseContract.integration_agent_value_company_total =
                contract.is_higher_education
                  ? contract.company.integration_agent_higher_education_value
                  : contract.company.integration_agent_value;
              parseContract.institution_value_company_total =
                applyRuleIsHigherEducation(
                  contract.is_higher_education,
                  contract.company.institution_value
                );
            } else {
              parseContract.integration_agent_value_company_total =
                calcValueProportional(
                  contract.company.integration_agent_value,
                  daysToCalcValueProportional
                );
              parseContract.institution_value_company_total =
                applyRuleIsHigherEducation(
                  contract.is_higher_education,
                  calcValueProportional(
                    contract.company.institution_value,
                    daysToCalcValueProportional
                  )
                );
            }
          } else {
            parseContract.integration_agent_value_company_total =
              contract.company.integration_agent_value;
            parseContract.institution_value_company_total =
              applyRuleIsHigherEducation(
                contract.is_higher_education,
                contract.company.institution_value
              );
          }
        }

        return {
          ...parseContract,
          id: contract.id,
          trainee_name: contract.trainee?.name,
          trainee_primary_phone_contact:
            contract.trainee?.primary_phone_contact,
          insurance_number: contract.insurance_number,
          company_name: contract?.company?.company_name,

          cnpj: contract?.company?.cnpj,
          fantasy_name: contract?.company?.fantasy_name,
          state: contract?.company?.state,

          status: contract.status ? "ATIVO" : "INATIVO",
          date_shutdown: "--",
          originalStatus: contract.status,
          total_value:
            contract.internship_scholarship_value +
            contract.transportation_assistance_value,
          trainee_date_birth: contract.trainee.date_birth
            ? format(
              parseISO(contract.trainee.date_birth.substring(0, 10)),
              "dd/MM/yyyy"
            )
            : "",
          total_value_formatted: new Intl.NumberFormat("pt", {
            style: "currency",
            currency: "BRL",
          }).format(
            contract.internship_scholarship_value +
            contract.transportation_assistance_value
          ),
          start_validity: format(
            parseISO(contract.start_validity.substring(0, 10)),
            "dd/MM/yyyy"
          ),
          count_date: differenceInDays(
            valueForm.ignore_contracts_date,
            startValidity
          ),
          integration_agent_value_company:
            contract.company.integration_agent_value,
          institution_value_company: contract.company.institution_value,
          isSuperior: contract.is_higher_education,
        };
      });

      const dataInactives = data.inactives.map((contract) => {
        let count = "---";

        const parseContract: any = {};
        const startValidity = parseISO(
          contract.start_validity.substring(0, 10)
        );
        const dateShutdown = parseISO(contract.date_shutdown.substring(0, 10));
        const dayDateShutdown = Number(
          format(dateShutdown, "dd", { locale: ptBR })
        );
        const daysdifference = differenceInDays(dateShutdown, startValidity);

        if (contract.company.type_charge === "INTEGRAL") {
          if (isSameMonth(dateShutdown, startValidity)) {
            if (daysdifference < 5 && dayDateShutdown < 11) {
              // verificar SE e 5 mesmo
              parseContract.integration_agent_value_company_total = 0;
              parseContract.institution_value_company_total = 0;
            } else {
              parseContract.integration_agent_value_company_total =
                contract.company.integration_agent_value;
              parseContract.institution_value_company_total =
                contract.company.institution_value;
            }
          } else {
            if (dayDateShutdown < 11) {
              parseContract.integration_agent_value_company_total = 0;
              parseContract.institution_value_company_total = 0;
            } else {
              parseContract.integration_agent_value_company_total =
                contract.company.integration_agent_value;
              parseContract.institution_value_company_total =
                contract.company.institution_value;
            }
          }
        } else if (contract.company.type_charge === "PROPORTIONAL") {
          if (isSameMonth(startValidity, dateShutdown)) {
            const differenceDays = differenceInDays(
              dateShutdown,
              startValidity
            );

            parseContract.integration_agent_value_company_total =
              calcValueProportional(
                contract.company.integration_agent_value,
                differenceDays
              );

            parseContract.institution_value_company_total =
              calcValueProportional(
                contract.company.institution_value,
                differenceDays
              );
          } else {
            parseContract.integration_agent_value_company_total =
              calcValueProportional(
                contract.company.integration_agent_value,
                dayDateShutdown
              );

            parseContract.institution_value_company_total =
              calcValueProportional(
                contract.company.institution_value,
                dayDateShutdown
              );
          }
        }

        return {
          
          ...parseContract,
          id: contract.id,
          trainee_name: contract.trainee?.name,
          trainee_date_birth: contract.trainee.date_birth
            ? format(
              parseISO(contract.trainee.date_birth.substring(0, 10)),
              "dd/MM/yyyy"
            )
            : "",
          trainee_primary_phone_contact:
            contract.trainee?.primary_phone_contact,
          insurance_number: contract.insurance_number,
          company_name: contract?.company?.company_name,

          cnpj: contract?.company?.cnpj,
          fantasy_name: contract?.company?.fantasy_name,
          state: contract?.company?.state,

          date_shutdown: format(
            parseISO(contract.date_shutdown.substring(0, 10)),
            "dd/MM/yyyy"
          ),
          status: contract.status ? "ATIVO" : "INATIVO",
          originalStatus: contract.status,
          total_value:
            contract.internship_scholarship_value +
            contract.transportation_assistance_value,
          start_validity: format(
            parseISO(contract.start_validity.substring(0, 10)),
            "dd/MM/yyyy"
          ),
          total_value_formatted: new Intl.NumberFormat("pt", {
            style: "currency",
            currency: "BRL",
          }).format(
            contract.internship_scholarship_value +
            contract.transportation_assistance_value
          ),
          count_date: count,
          integration_agent_value_company:
            contract.company.integration_agent_value,
          institution_value_company: contract.company.institution_value,
          isSuperior: contract.is_higher_education,
        };
      });

      setContracts([...dataActives, ...dataInactives]);
    },
    [getValues]
  );

  const OnSubmit = useCallback(
    (data) => {
      setLoading(true);
      let queryParams = new URLSearchParams();

      if (data.ignore_contracts_date)
        queryParams.set(
          "ignore_contracts_date",
          format(data.ignore_contracts_date, "yyyy-MM-dd")
        );

      if (data.date_start_end_validity)
        queryParams.set(
          "date_start_end_validity",
          format(data.date_start_end_validity, "yyyy-MM-dd")
        );
      if (data.date_end_end_validity)
        queryParams.set(
          "date_end_end_validity",
          format(data.date_end_end_validity, "yyyy-MM-dd")
        );

      if (data.company_id) {
        queryParams.set(
          "company_id",
          data.company_id.reduce((acc, current) => {
            return [...acc, current.value];
          }, [])
        );
      }

      api
        .get(`reports/financial?${queryParams}`)
        .then((response) => {
          handleSetReport(response.data);
        })

        .finally(() => setLoading(false));
    },
    [handleSetReport]
  );

  const handleGenerateExcelFileFinanceiro = () => {
    // Agrupar os contratos por empresa
    const groupedContracts = contracts.reduce((acc, item) => {
      const companyName = item.company_name || "Empresa Desconhecida";
      const cnpj = item.cnpj || "CNPJ Não Informado";
      const fantasyName = item.fantasy_name || "Nome Fantasia Não Informado";
      const state = item.state || "Estado Não Informado";

      if (!acc[companyName]) {
        acc[companyName] = {
          state: state,
          company_name: companyName,
          cnpj: cnpj,
          fantasy_name: fantasyName,
          total_interns: 0,
          active_interns: 0,
          higher_education_interns: 0,
          inactive_interns: 0,
          integration_agent_value_sum: 0,
          institution_value_sum: 0,
          active_value_sum: 0,
          inactive_value_sum: 0,
          higher_education_value_sum: 0,
          total_value_sum: 0,
        };
      }

      acc[item.company_name].total_interns += 1;

      let totalValue = 0;

      //console.log(item.type_charge);

      if (item.isSuperior) {
        // Soma apenas o valor para estagiários de nível superior

        const integrationValue = parseFloat(
          item.integration_agent_value_company_total || 0
        );
        const institutionValue = 0;
        totalValue = integrationValue + institutionValue;

        acc[item.company_name].higher_education_interns += 1;
        acc[item.company_name].institution_value_sum += institutionValue;
        acc[item.company_name].higher_education_value_sum += integrationValue;
      } else {
        // Soma os valores padrão para estagiários não superiores
        const integrationValue = parseFloat(
          item.integration_agent_value_company_total || 0
        );
        const institutionValue = parseFloat(
          item.institution_value_company_total || 0
        );
        totalValue = integrationValue + institutionValue;

        acc[item.company_name].integration_agent_value_sum += integrationValue;
        acc[item.company_name].institution_value_sum += institutionValue;
      }

      if (item.status === "ATIVO") {
        acc[item.company_name].active_interns += 1;
        acc[item.company_name].active_value_sum += totalValue;
      } else {
        acc[item.company_name].inactive_interns += 1;
        acc[item.company_name].inactive_value_sum += totalValue;
      }

      acc[item.company_name].total_value_sum += totalValue;

      return acc;
    }, {});

    // Preparar os dados agrupados para o Excel
    const parseContracts = Object.values(groupedContracts).map(
      (company: any) => ({
        state: company.state,
        company_name: company.company_name,
        fantasy_name: company.fantasy_name,
        cnpj: company.cnpj,
        total_interns: company.total_interns,
        active_interns: company.active_interns,
        higher_education_interns: company.higher_education_interns,
        inactive_interns: company.inactive_interns,
        integration_agent_value_sum: (
          company.integration_agent_value_sum +
          company.higher_education_value_sum
        ).toFixed(2),
        institution_value_sum: company.institution_value_sum.toFixed(2),
        active_value_sum: company.active_value_sum.toFixed(2),
        inactive_value_sum: company.inactive_value_sum.toFixed(2),
        higher_education_value_sum:
          company.higher_education_value_sum.toFixed(2),
        total_value_sum: company.total_value_sum.toFixed(2),
      })
    );

    let option = {
      fileName: `${Date.now()}-financeiro`,
      datas: [
        {
          sheetData: parseContracts,
          sheetName: "Empresas",
          sheetFilter: [
            "state",
            "company_name",
            "fantasy_name",
            "cnpj",
            "total_interns",
            "active_interns",
            "higher_education_interns",
            "inactive_interns",
            "integration_agent_value_sum",
            "institution_value_sum",
            "active_value_sum",
            "inactive_value_sum",
            "higher_education_value_sum",
            "total_value_sum",
          ],
          sheetHeader: [
            "Estado",
            "Empresa",
            "Nome Fantasia",
            "CNPJ",
            "N° Estagiários Cobrados",
            "Ativos",
            "Superior",
            "Inativos",
            "V. Ag de Integração",
            "V. Escola",
            "Soma (Ativos)",
            "Soma (Inativos)",
            "Soma (Superior)",
            "Soma (Total)",
          ],
        },
      ],
    };

    let toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  };

  const handleGenerateExcelFile = () => {
    const parseContracts = contracts.map((item) => {
      return {
        name: item.trainee_name,
        trainee_date_birth: item.trainee_date_birth,
        contact: item.trainee_primary_phone_contact,
        company_name: item.company_name,
        total_value_formatted: item.total_value,
        start_validity: item.start_validity,
        date_shutdown: item.date_shutdown,
        status: item.status,
      };
    });

    let option = {
      fileName: `${Date.now()}-financeiro`,
      datas: [
        {
          sheetData: parseContracts,
          sheetName: "sheet",
          sheetFilter: [
            "name",
            "trainee_date_birth",
            "contact",
            "company_name",
            "total_value_formatted",
            "start_validity",
            "date_shutdown",
            "status",
          ],
          sheetHeader: [
            "Nome",
            "Data de nascimento",
            "Contato",
            "Empresa",
            "Auxílio Bolsa",
            "Data do Cadastro",
            "Data do desligamento",
            "status",
          ],
        },
      ],
    };

    let toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  };

  useEffect(() => {
    api.get("companies").then((response) => {
      setCompaniesOptions([
        ...companiesOptions,
        ...response.data.map((item) => {
          return {
            value: item.id,
            label: item.company_name,
          };
        }),
      ]);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container fluid>
      <Card>
        <Card.Header
          as="h5"
          className="d-flex align-items-center color-secondary"
        >
          Relatório Financeiro
        </Card.Header>
        <Card.Body style={{ display: "flex", flexDirection: "column" }}>
          <Form onSubmit={handleSubmit(OnSubmit)}>
            <Form.Row>
              <Controller
                as={InputDate}
                control={control}
                name="ignore_contracts_date"
                label="Ignorar os contratos ativos apartir de:"
                md="12"
              />
            </Form.Row>

            <Form.Row>
              <Controller
                as={InputDate}
                control={control}
                name="date_start_end_validity"
                label="Considerar os contratos inativos - Data inicial "
                md="6"
              />
              <Controller
                as={InputDate}
                control={control}
                name="date_end_end_validity"
                label="Considerar os contratos inativos - Data final"
                md="6"
              />
            </Form.Row>

            <Form.Row>
              <Controller
                as={Select}
                control={control}
                name="company_id"
                label="Qual a empresa ?"
                md="12"
                options={companiesOptions}
                isMulti
              />
              <Controller
                as={Select}
                control={control}
                name="omitir_value"
                label="Omitir coluna de valores ?"
                md="12"
                options={[
                  {
                    value: true,
                    label: "Sim",
                  },
                  {
                    value: false,
                    label: "Não",
                  },
                ]}
              />
              <Controller
                as={Select}
                control={control}
                name="showData"
                label="Mostrar no PDF ?"
                md="12"
                options={[
                  {
                    value: "",
                    label: "Todos os registros",
                  },
                  {
                    value: "ATIVO",
                    label: "Apenas Ativos",
                  },
                  {
                    value: "INATIVO",
                    label: "Apenas Inativos",
                  },
                ]}
              />
            </Form.Row>
            <div className="d-flex justify-content-between mb-4">
              <Button
                onClick={() => { }}
                className="button-primary"
                type="submit"
              >
                <FaFilter className="mr-2" />
                {loading ? "Buscando..." : "Filtrar"}
              </Button>
              <div className="d-flex">
                <Button
                  type="button"
                  variant="secondary"
                  onClick={() =>
                    handleGenerateReportFinancial(
                      contracts,
                      "",
                      omitir_value,
                      showData
                    )
                  }
                  className="btn btn-secondary mr-3"
                  disabled={!contracts.length}
                >
                  <FaFilePdf className="mr-2" size={20} />
                  Gerar PDF
                </Button>
                <Button
                  type="button"
                  variant="secondary"
                  onClick={() => handleGenerateExcelFile()}
                  className="button-secondary-outline mr-3"
                  disabled={!contracts.length}
                >
                  <RiFileExcel2Fill className="mr-2" />
                  Gerar Excel
                </Button>

                <Button
                  type="button"
                  onClick={() => handleGenerateExcelFileFinanceiro()}
                  className="button-secondary"
                  disabled={!contracts.length}
                >
                  <RiFileExcel2Fill className="mr-2" />
                  Relatório FInanceiro
                </Button>
              </div>
            </div>
          </Form>

          {loading ? (
            <LoadingIndicator />
          ) : (
            <>
              <Row>
                <Col sm="4">
                  <Card
                    style={{
                      background: "var(--bg-secondary)",
                      borderColor: "var(--secondary)",
                      color: "var(--secondary)",
                    }}
                    className="mb-4"
                  >
                    <Card.Body>
                      <Card.Title>Total</Card.Title>
                      <Card.Subtitle
                        className="mb-2"
                        style={{ fontSize: "36px", fontWeight: "bold" }}
                      >
                        {contracts.length}
                      </Card.Subtitle>
                    </Card.Body>
                  </Card>
                </Col>
                <Col sm="4">
                  <Card
                    style={{
                      background: "var(--bg-secondary)",
                      borderColor: "var(--primary)",
                      color: "var(--primary)",
                    }}
                    className="mb-4"
                  >
                    <Card.Body>
                      <Card.Title>Ativos</Card.Title>
                      <Card.Subtitle
                        className="mb-2"
                        style={{ fontSize: "36px", fontWeight: "bold" }}
                      >
                        {
                          contracts.filter(
                            (item) => item.originalStatus === true
                          ).length
                        }
                      </Card.Subtitle>
                    </Card.Body>
                  </Card>
                </Col>
                <Col sm="4">
                  <Card
                    style={{
                      background: "var(--bg-secondary)",
                      borderColor: "var(--slate-400)",
                      color: "var(--slate-400)",
                    }}
                    className="mb-4"
                  >
                    <Card.Body>
                      <Card.Title>Inativos</Card.Title>
                      <Card.Subtitle
                        className="mb-2"
                        style={{ fontSize: "36px", fontWeight: "bold" }}
                      >
                        {
                          contracts.filter(
                            (item) => item.originalStatus === false
                          ).length
                        }
                      </Card.Subtitle>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              <ComplexTable
                data={contracts}
                columns={[
                  {
                    dataField: "count_date",
                    text: "Dias",
                    sort: true,
                    filter: false,
                  },
                  {
                    dataField: "isSuperior",
                    text: "Superior",
                    sort: true,
                    filter: false,
                    formatter: (_, row) => (
                      <div>{row.isSuperior ? "sim" : "nao"}</div>
                    ),
                  },
                  {
                    dataField: "integration_agent_value_company",
                    text: "Valor cheio",
                    sort: true,
                    filter: false,
                    formatter: (_, row) => (
                      <div>
                        {row.integration_agent_value_company?.toFixed(2)}
                      </div>
                    ),
                  },
                  {
                    dataField: "integration_agent_value_company_total",
                    text: "Valor cobrado agente ",
                    sort: true,
                    filter: false,
                    formatter: (_, row) => (
                      <div>
                        {row.integration_agent_value_company_total?.toFixed(2)}
                      </div>
                    ),
                  },
                  {
                    dataField: "institution_value_company",
                    text: "Valor cheio escola",
                    sort: true,
                    filter: false,
                    formatter: (_, row) => (
                      <div>{row.institution_value_company?.toFixed(2)}</div>
                    ),
                  },
                  {
                    dataField: "institution_value_company_total",
                    text: "Valor cobrado escola",
                    sort: true,
                    filter: false,
                    formatter: (_, row) => (
                      <div>
                        {row.institution_value_company_total?.toFixed(2)}
                      </div>
                    ),
                  },
                  {
                    dataField: "trainee_name",
                    text: "Estagiário",
                    sort: true,
                    filter: false,
                  },

                  {
                    dataField: "start_validity",
                    text: "Data do cadastro",
                    sort: false,
                    filter: false,
                  },
                  {
                    dataField: "date_shutdown",
                    text: "Data de desligamento",
                    sort: false,
                    filter: false,
                  },
                  {
                    dataField: "status",
                    text: "Status",
                    sort: true,
                    filter: false,
                  },
                ]}
              />
            </>
          )}
        </Card.Body>
      </Card>
    </Container>
  );
};

export default FinancialReport;
