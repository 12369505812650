import api from "./axios";

class AttendaceService {
  private endpoint = "/attendaces/contracts";

  async fetchAllAttendacesByContractId(contractId: string) {
    const { data } = await api.get(`${this.endpoint}/${contractId}`);
    return data;
  }
}

const attendaceService = new AttendaceService();

export default attendaceService;