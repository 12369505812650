import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
} from "react";
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Tooltip,
  Legend,
} from "recharts";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { toast } from "react-toastify";
import { format, addMonths, subMonths } from "date-fns";
import ptBr from "date-fns/locale/pt-BR";
import api from "../../services/axios";
import "./style.css";

import { Calendar } from "./components/Calendar";


import ProgressBarMeta from "./components/ProgressbarMeta";
import { ModalDetails } from "./modalDetails";

const COLORS = [
  "#FF6384",
  "#36A2EB",
  "#28a745",
  "#fd7e14",
  "#dc3545",
  "#343a40",
  "#20c997",
  "#6610f2",
];

const Dashboard: React.FC = () => {

  const [customMessages, setCustomMessages] = useState({
    inactives_contracts_same_month_year_message: "",
    actives_contracts_same_month_year_message: "",
    renewal_contracts_same_moth_year_message: "",
  });

  const [resume, setResume] = useState({
    total_contracts: 0,
    shutdown_contracts: 0,
    actives_contracts: 0,
    total_trainees: 0,

    total_companies: 0,
    active_companies: 0,
    categories_companies_actives: {
      "5": 0,
      "10": 0,
      "15": 0,
      "20": 0,
      "20+": 0,
    },
    total_trainees_abandonments: 0,
    groups_reasons_shutdowns: [],
    categories_reasons_group: {
      "15": 0,
      "30": 0,
      "60": 0,
      "90": 0,
      "90+": 0,
    },
  });

  const [currentMonth, setCurrentMonth] = useState<Date>(new Date());

  const monthName = useMemo(
    () =>
      format(currentMonth, "MMMM 'de' yyyy", { locale: ptBr }).toUpperCase(),
    [currentMonth]
  );

  const handleAddMonth = useCallback(
    () => setCurrentMonth(addMonths(currentMonth, 1)),
    [currentMonth]
  );
  const handleSubMonth = useCallback(
    () => setCurrentMonth(subMonths(currentMonth, 1)),
    [currentMonth]
  );

  const [companiesByState, setCompaniesByState] = useState([]);
  const [traineesByState, setTraineesByState] = useState([]);

  useEffect(() => {
    const year = format(currentMonth, "yyyy");
    const month = format(currentMonth, "MM");

    api
      .get(`dashboard?year=${year}&month=${month}`)
      .then((response) => {
        setCompaniesByState(response.data.total_companies_by_state);
        setTraineesByState(response.data.total_trainees_by_state);

        setResume({
          total_trainees: Number(response.data.total_trainees),
          total_companies: Number(response.data.total_companies),
          active_companies: Number(response.data.active_companies),

          categories_companies_actives:
            response.data.categories_companies_actives,
          total_contracts: Number(response.data.total_contracts),
          shutdown_contracts: Number(response.data.shutdown_contracts),
          actives_contracts: Number(response.data.actives_contracts),
          total_trainees_abandonments: Number(
            response.data.total_trainees_abandonments || 0
          ),
          groups_reasons_shutdowns: response.data.groups_reasons_shutdowns.map(
            (item) => ({
              name: item.reason_shutdown,
              value: Number(item.qtd),
            })
          ),
          categories_reasons_group: response.data.categories_reasons_group,
        });

        setCustomMessages({
          inactives_contracts_same_month_year_message:
            response.data.inactives_contracts_same_month_year,
          actives_contracts_same_month_year_message:
            response.data.actives_contracts_same_month_year,
          renewal_contracts_same_moth_year_message:
            response.data.renewal_contracts_same_moth_year_message,
        });
      })
      .catch(() => toast.error("Não conseguimos recuperar as informações"));
  }, [currentMonth]);

  const labels = [
    { name: "Contratos desligados", value: resume.shutdown_contracts },
    {
      name: `Novos Contratos (${customMessages.actives_contracts_same_month_year_message} Ativos, ${customMessages.inactives_contracts_same_month_year_message} desligados),  ${customMessages.renewal_contracts_same_moth_year_message} Renovados`,
      value: resume.actives_contracts,
    },
  ];

  const labelsGroup = [
    { name: "Até 15 dias", value: resume.categories_reasons_group["15"] },
    { name: "Até 30 dias", value: resume.categories_reasons_group["30"] },
    { name: "Até 60 dias", value: resume.categories_reasons_group["60"] },
    { name: "Até 90 dias", value: resume.categories_reasons_group["90"] },
    { name: "Acima de 90 dias", value: resume.categories_reasons_group["90+"] },
  ];

  const labelsGroupActiveCompanies = [
    { name: "Até 5 ", value: resume.categories_companies_actives["5"] },
    { name: "Até 10", value: resume.categories_companies_actives["10"] },
    { name: "Até 15", value: resume.categories_companies_actives["15"] },
    { name: "Até 20", value: resume.categories_companies_actives["20"] },
    { name: "Acima de 20", value: resume.categories_companies_actives["20+"] },
  ];

  return (
    <>
      {/* <Snow /> */}

      <Calendar />
      <ProgressBarMeta value={resume?.total_contracts} />

      <Container fluid>
        <div className="month-navigation">
          <button className="nav-btn btn-left" onClick={handleSubMonth}>
            <i className="bi bi-arrow-left-circle"></i>
            <span className="month-navigation-title">Anterior</span>
          </button>
          <div className="month-name">{monthName}</div>
          <button className="nav-btn btn-right" onClick={handleAddMonth}>
            <span className="month-navigation-title">Próximo</span>
            <i className="bi bi-arrow-right-circle"></i>
          </button>
        </div>

        <div className="data-counts">
          <div className="data-count-item">
            <h3 className="data-count-title">Total</h3>
            <p className="data-count-value">{resume.total_contracts}</p>
          </div>
          <div className="data-count-item">
            <h3 className="data-count-title">Total de cadastros</h3>
            <p className="data-count-value">{resume.total_trainees}</p>
          </div>
          <div className="data-count-item">
            <h3 className="data-count-title">Total de empresas</h3>
            <p className="data-count-value">{resume.total_companies}</p>
          </div>
          <div className="data-count-item">
            <h3 className="data-count-title">Empresas Ativas</h3>
            <p className="data-count-value">{resume.active_companies}</p>
          </div>
        </div>

        <Row>
          <Col sm={12}>
            <Row>
              <Col className="mb-4" sm="4">
                <Card>
                  <Card.Body className="justify-content-between d-flex flex-column">
                    <Card.Title className="text-center">
                      Quantidade de contratos feitos e desligados

                    </Card.Title>
                    <ResponsiveContainer width="100%" height={400}>
                      <PieChart>
                        <Pie
                          dataKey="value"
                          isAnimationActive={true}
                          data={labels}
                          cx="50%"
                          cy="50%"
                          outerRadius={80}
                          fill="#8884d8"
                          label
                        >
                          {labels.map((_, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={COLORS[index % COLORS.length]}
                            />
                          ))}
                        </Pie>
                        <Tooltip />
                        <Legend className="custom-legend" />
                      </PieChart>
                    </ResponsiveContainer>
                    <ModalDetails url="/some-url" />
                  </Card.Body>
                </Card>
              </Col>
              <Col className="mb-4" sm="4">
                <Card>
                  <Card.Body className="justify-content-between d-flex flex-column">
                    <Card.Title className="text-center">
                      Motivo de desligamentos
                    </Card.Title>
                    <ResponsiveContainer width="100%" height={400}>
                      <PieChart>
                        <Pie
                          dataKey="value"
                          isAnimationActive={true}
                          data={resume.groups_reasons_shutdowns}
                          cx="50%"
                          cy="50%"
                          outerRadius={80}
                          fill="#8884d8"
                          label
                        >
                          {resume.groups_reasons_shutdowns.map((_, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={COLORS[index % COLORS.length]}
                            />
                          ))}
                        </Pie>
                        <Tooltip />
                        <Legend className="custom-legend" />
                      </PieChart>
                    </ResponsiveContainer>
                    <ModalDetails url="/some-url" />
                  </Card.Body>
                </Card>
              </Col>

              <Col className="mb-4" sm="4">
                <Card>
                  <Card.Body className="justify-content-between d-flex flex-column">
                    <Card.Title className="text-center">
                      Desligados por iniciativa do estagiário
                    </Card.Title>
                    <ResponsiveContainer width="100%" height={400}>
                      <PieChart>
                        <Pie
                          dataKey="value"
                          isAnimationActive={true}
                          data={labelsGroup}
                          cx="50%"
                          cy="50%"
                          outerRadius={80}
                          fill="#8884d8"
                          label
                        >
                          {resume.groups_reasons_shutdowns.map((_, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={COLORS[index % COLORS.length]}
                            />
                          ))}
                        </Pie>
                        <Tooltip />
                        <Legend className="custom-legend" />
                      </PieChart>
                    </ResponsiveContainer>
                    <ModalDetails url="/some-url" />
                  </Card.Body>
                </Card>
              </Col>
              <Col className="mb-4" sm="4">
                <Card>
                  <Card.Body className="justify-content-between d-flex flex-column">
                    <Card.Title className="text-center">
                      Estágiários Ativos Por Estado
                    </Card.Title>
                    <ResponsiveContainer width="100%" height={400}>
                      <PieChart>
                        <Pie
                          dataKey="value"
                          isAnimationActive={true}
                          data={traineesByState}
                          cx="50%"
                          cy="50%"
                          outerRadius={80}
                          fill="#8884d8"
                          label
                        >
                          {labels.map((_, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={COLORS[index % COLORS.length]}
                            />
                          ))}
                        </Pie>
                        <Tooltip />
                        <Legend className="custom-legend" />
                      </PieChart>
                    </ResponsiveContainer>
                  </Card.Body>
                </Card>
              </Col>

              <Col className="mb-4" sm="4">
                <Card>
                  <Card.Body className="justify-content-between d-flex flex-column">
                    <Card.Title className="text-center">
                      Empresas Ativas Por Estado
                    </Card.Title>
                    <ResponsiveContainer width="100%" height={400}>
                      <PieChart>
                        <Pie
                          dataKey="value"
                          isAnimationActive={true}
                          data={companiesByState}
                          cx="50%"
                          cy="50%"
                          outerRadius={80}
                          fill="#8884d8"
                          label
                        >
                          {companiesByState.map((_, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={COLORS[index % COLORS.length]}
                            />
                          ))}
                        </Pie>
                        <Tooltip />
                        <Legend className="custom-legend" />
                      </PieChart>
                    </ResponsiveContainer>
                  </Card.Body>
                </Card>
              </Col>

              <Col className="mb-4" sm="4">
                <Card>
                  <Card.Body className="justify-content-between d-flex flex-column">
                    <Card.Title className="text-center">
                      Quantidade de estagiários por CNPJ
                    </Card.Title>
                    <ResponsiveContainer width="100%" height={400}>
                      <PieChart>
                        <Pie
                          dataKey="value"
                          isAnimationActive={true}
                          data={labelsGroupActiveCompanies}
                          cx="50%"
                          cy="50%"
                          outerRadius={80}
                          fill="#8884d8"
                          label
                        >
                          {resume.groups_reasons_shutdowns.map((_, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={COLORS[index % COLORS.length]}
                            />
                          ))}
                        </Pie>
                        <Tooltip />
                        <Legend className="custom-legend" />
                      </PieChart>
                    </ResponsiveContainer>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Dashboard;
